import Vue from "vue";
import Vuex from "vuex";
import messages from "./store.messages";
import admins from "./store.admins";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        phoneMask: '+# (###) ###-##-##',
        defaultAvatar: "/img/avatar.png",
        defaultImage: "/img/no-image.png",
        defaultLogo: "/img/no-logo.png",
        userAvatar: "/img/avatar.png",
        userId: null,
        userUuid: null,
        userName: null,
        userBlock: false,
        userInitials: null,
        userPosition: null,
        userEmployeePosition: null,
        itemsPerPage: 10,
        perPageItems: [5, 10, 25, 50, 100],
        listLanguages: ['ru'],
        listLanguageNames: [],
        showNotifyMessages: "unread",
        countUnreadNotifications: 0,
        BusinessProcessId: 0,
        WorkDayStatusId: null,
        WorkDayStart: false,
        country_id: 0,
        lang: "ru",
        timezone: "Asia/Almaty",
        currency: "KZT",
        defaultCurrency: "KZT",
        isPbx: false,
        theme: null,
        funnelCustomer: 1,
        formAdministrator: {
            administrator: null,
            email: null,
            phone: null,
            active: null,
            page: null,
            perPage: null,
            sortBy: null,
            sortDir: null,
        },
        notifications: [],
        notificationGroups: []
    },
    mutations: {
        updatePhoneMask(state, phoneMask) {
            state.phoneMask = phoneMask
        },
        updateLanguage(state, language) {
            state.lang = language
        },
        updateListLanguages(state, listLanguages) {
            state.listLanguages = listLanguages
        },
        updateListLanguageNames(state, listLanguageNames) {
            state.listLanguageNames = listLanguageNames
        },
        updateTimezone(state, timezone) {
            state.timezone = timezone
        },
        updateDefaultAvatar(state, defaultAvatar) {
            state.defaultAvatar = defaultAvatar
        },
        updateDefaultImage(state, defaultImage) {
            state.defaultImage = defaultImage
        },
        updateDefaultLogo(state, defaultLogo) {
            state.defaultLogo = defaultLogo
        },
        updateDefaultCurrency(state, defaultCurrency) {
            state.defaultCurrency = defaultCurrency
        },
        updateCurrency(state, currency) {
            state.currency = currency
        },
        updateItemsPerPage(state, itemsPerPage) {
            state.itemsPerPage = itemsPerPage
        },
        updatePerPageItems(state, perPageItems) {
            state.perPageItems = perPageItems
        },
        updateIsPbx(state, isPbx) {
            state.isPbx = isPbx
        },
        updateShowNotifyMessages(state, showNotifyMessages) {
            state.showNotifyMessages = showNotifyMessages
        },
        updateUserAvatar(state, avatar) {
            state.userAvatar = avatar
        },
        updateUserName(state, name) {
            state.userName = name
        },
        updateUserId(state, id) {
            state.userId = id
        },
        updateUserUuid(state, uuid) {
            state.userUuid = uuid
        },
        updateUserBlock(state, block_action) {
            state.userBlock = block_action
        },
        updateBusinessProcessId(state, BusinessProcessId) {
            state.BusinessProcessId = BusinessProcessId
        },
        updateWorkDayStatusId(state, WorkDayStatusId) {
            state.WorkDayStatusId = WorkDayStatusId
        },
        updateWorkDayStart(state, WorkDayStart) {
            state.WorkDayStart = WorkDayStart
        },
        updateUserInitials(state, name) {
            state.userInitials = name.firstLetterCaps()
        },
        updateUserPosition(state, position) {
            state.userPosition = position
        },
        updateEmployeePosition(state, employee_position) {
            state.userEmployeePosition = employee_position
        },
        updateFormAdministrator(state, data) {
            state.formAdministrator = data
        },
        updateTheme(state, theme) {
            state.theme = theme
        },
        updateCountUnreadNotifications(state, countUnreadNotifications) {
            state.countUnreadNotifications = countUnreadNotifications
        },
        updateNotifications(state, notifications) {
            state.notifications = notifications
        },
        updateNotificationGroups(state, notificationGroups) {
            state.notificationGroups = notificationGroups
        }
    },
    actions: {
        setSettings({commit}, user) {
            if (user && user.defaultAvatar) {
                commit('updateDefaultAvatar', user.defaultAvatar)
            }
            if (user && user.defaultImage) {
                commit('updateDefaultImage', user.defaultImage)
            }
            if (user && user.defaultLogo) {
                commit('updateDefaultLogo', user.defaultLogo)
            }
            if (user && user.defaultCurrency) {
                commit('updateDefaultCurrency', user.defaultCurrency)
                commit('updateCurrency', user.currency)
            }
            if (user && user.itemsPerPage) {
                commit('updateItemsPerPage', user.itemsPerPage)
            }
            if (user && user.perPageItems) {
                commit('updatePerPageItems', user.perPageItems)
            }
            if (user && user.BusinessProcessId) {
                commit('updateBusinessProcessId', user.BusinessProcessId)
            }
            if (user && user.WorkDayStatusId) {
                commit('updateWorkDayStatusId', user.WorkDayStatusId)
            }
            if (user && user.WorkDayStart) {
                commit('updateWorkDayStart', user.WorkDayStart)
            }
            if (user && user.language) {
                commit('updateLanguage', user.language)
            }
            if (user && user.block_action) {
                commit('updateUserBlock', user.block_action)
            }
            if (user && user.listLanguages) {
                commit('updateListLanguages', user.listLanguages)
            }
            if (user && user.listLanguageNames) {
                commit('updateListLanguageNames', user.listLanguageNames)
            }
            if (user && user.timezone) {
                commit('updateTimezone', user.timezone)
            }
            if (user && user.isPbx) {
                commit('updateIsPbx', user.isPbx)
            }
            if (user && user.showNotifyMessages) {
                commit('updateShowNotifyMessages', user.showNotifyMessages)
            }
            if (user && user.theme) {
                commit('updateTheme', user.theme)
            }
            if (user && user.employee_position) {
                commit('updateEmployeePosition', user.employee_position)
            }
        },
        setLanguage({commit}, language) {
            commit('updateLanguage', language)
        },
        setUserBlock({commit}, block_action) {
            commit('updateUserBlock', block_action)
        },
        setBusinessProcessId({commit}, BusinessProcessId) {
            commit('updateBusinessProcessId', BusinessProcessId)
        },
        setWorkDayStatusId({commit}, WorkDayStatusId) {
            commit('updateWorkDayStatusId', WorkDayStatusId)
        },
        setWorkDayStart({commit}, WorkDayStart) {
            commit('updateWorkDayStart', WorkDayStart)
        },
        setCurrency({commit}, currency) {
            commit('updateCurrency', currency)
        },
        setUserAvatar({commit}, avatar) {
            commit('updateUserAvatar', avatar)
        },
        setUserName({commit}, name) {
            commit('updateUserName', name)
        },
        setUserId({commit}, id) {
            commit('updateUserId', id)
        },
        setUserUuid({commit}, uuid) {
            commit('updateUserUuid', uuid)
        },
        setUserInitials({commit}, name) {
            commit('updateUserInitials', name)
        },
        setUserPosition({commit}, position) {
            commit('updateUserPosition', position)
        },
        setEmployeePosition({commit}, employee_position) {
            commit('updateEmployeePosition', employee_position)
        },
        setFormAdministrator({commit}, data) {
            commit('updateFormAdministrator', data)
        },
        setCountUnreadNotifications({commit}, data) {
            commit('updateCountUnreadNotifications', data)
        },

        async fetchNotifications({commit}) {
            try {
                Vue.http.get('admin/notification', {
                    params: {
                        perPage: 12
                    }
                })
                    .then(({body}) => {
                        commit('updateNotifications', body.data);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        throw error;
                    });
            } catch (error) {
                console.error('Ошибка получения уведомлений:', error);
            }
        },

        async fetchNotificationGroups({commit}) {
            try {
                Vue.http.get('admin/notification_group')
                    .then(({body}) => {
                        commit('updateNotificationGroups', body);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                        throw error;
                    });
            } catch (error) {
                console.error('Ошибка получения уведомлений:', error);
            }
        },

        async notificationRead({commit}, item) {
             await Vue.http
                .put(`admin/notification/${item.uuid}`)
                .then(res => {
                    item.read = 1;
                })
                .catch(err => {
                    this.$toastr.error(this.$t('notify_has_not_been_updated'))
                });
        },

        readEntityNotification({commit, dispatch},{entity, uuid}) {
            return new Promise((resolve, reject) => {
                Vue.http.post(
                    `admin/${entity}/${uuid}/notification/read`,
                    {
                        params: {
                            read: 1
                        }
                    }
                ).then(() => {
                    resolve();
                }).catch(() => {
                    reject();
                });
            })
        },
    },
    getters: {
        phoneMask: state => state.phoneMask,
        lang: state => state.lang,
        language: state => state.lang,
        listLanguages: state => state.listLanguages,
        listLanguageNames: state => state.listLanguageNames,
        showNotifyMessages: state => state.showNotifyMessages,
        timezone: state => state.timezone,
        defaultAvatar: state => state.defaultAvatar,
        defaultImage: state => state.defaultImage,
        defaultLogo: state => state.defaultLogo,
        defaultCurrency: state => state.defaultCurrency,
        currency: state => state.currency,
        itemsPerPage: state => state.itemsPerPage,
        perPageItems: state => state.perPageItems,
        funnelCustomer: state => state.funnelCustomer,
        userAvatar: state => state.userAvatar,
        userName: state => state.userName,
        userId: state => state.userId,
        userUuid: state => state.userUuid,
        userBlock: state => state.userBlock,
        BusinessProcessId: state => state.BusinessProcessId,
        WorkDayStatusId: state => state.WorkDayStatusId,
        WorkDayStart: state => state.WorkDayStart,
        userInitials: state => state.userInitials,
        userPosition: state => state.userPosition,
        userEmployeePosition: state => state.userEmployeePosition,
        formAdministrator: state => state.formAdministrator,
        theme: state => state.theme,
        countUnreadNotifications: state => state.countUnreadNotifications,
        notifications: state => state.notifications,
        notificationGroups: state => state.notificationGroups,
        businessDayEntrance: state => process.env.VUE_APP_BUSINESS_DAY_ENTRANCE,
        unreadNotifications: state => state.notifications.filter(item => item.read === 0)
    },
    modules: {
        messages,
        admins
    }
});
